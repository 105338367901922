import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form','tabContentLeft','tabContentRight','tabLeft','tabRight']

  connect() {
    const searchParams = new URLSearchParams(window.location.search)
    const param = searchParams.get('view')

    this.toggleNavTab(param)
  }

  toggleNavTab(param) {
    if (param == 'right') {
      this.tabLeftTarget.classList.remove('active')
      this.tabContentLeftTarget.classList.remove('show','active')
      this.tabRightTarget.classList.add('active')
      this.tabContentRightTarget.classList.add('active','show')
    } else {
      this.tabRightTarget.classList.remove('active')
      this.tabContentRightTarget.classList.remove('active','show')
      this.tabLeftTarget.classList.add('active')
      this.tabContentLeftTarget.classList.add('active','show')
    }
  }

  showTab(side) {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set('view', side.params.url)
    searchParams.toString()
    window.history.pushState({}, '', 'agendas?' + searchParams.toString())
  }
}
