import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {

    const invalidInput = document.querySelector('.is-invalid')

    if (invalidInput) {
      invalidInput.scrollIntoView({block: 'center'})
      invalidInput.focus()
    }
  }
}
