import '@hotwired/turbo-rails'
import $ from 'jquery'
import * as ActiveStorage from '@rails/activestorage'
import '@popperjs/core'
import '../src/imports/bootstrap.js'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ui/ui/widgets/sortable'
import 'moment'
import 'moment-timezone'
import 'moment/locale/de'
import 'bootstrap-table/dist/bootstrap-table.min.js'
import 'bootstrap-table/dist/locale/bootstrap-table-de-DE.min.js'
import Rails from '@rails/ujs'
import tippy from 'tippy.js'
import 'src/tooltips'
import sal from 'sal.js'

sal()
ActiveStorage.start()
Rails.start()

window.jQuery = $
window.$ = $

require('jquery.Jcrop')
require('@nathanvda/cocoon')
require('chartkick/chart.js')
require('trix')
require('@rails/actiontext')
require('trix-custom')
require('simplycountdown.js/dist/simplyCountdown.min.js')

import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'
flatpickr.localize(German)

import 'trix/dist/trix.css'

require('../src/library/rails_sortable')($, Rails)

require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line

let req = require.context('../src/application', true)
req.keys().forEach(req)

window.tippy = tippy

import 'controllers' // stimulus
