/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['date','dateTime']

  connect() {

    flatpickr('.datepicker', { dateFormat: 'd.m.Y' })

    flatpickr('#datetimepicker', { enableTime: true, dateFormat: 'd.m.Y H:i' })
  }
}
