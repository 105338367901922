import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {
    if (this.inputCanonicalUrl)  this.inputCanonicalUrl.style.display = 'none'
  }
  
  toggleInputCanonicalUrl() {
    const inputCanonicalUrlHidden = this.inputCanonicalUrl.style.display == 'none'

    if (inputCanonicalUrlHidden) {
      this.inputCanonicalUrl.style.display = 'block'
      document.getElementById('article_canonical_url').focus()
    } else {
      this.inputCanonicalUrl.style.display = 'none'
    }
  }

  get inputCanonicalUrl() {
    return document.querySelector('.article_canonical_url')
  }
}
