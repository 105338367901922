import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['messageTimestamp']

  connect () {
    this.startRefreshing()
  }

  disconnect () {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refreshTimestamps()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  refreshTimestamps() {
    const currentDate = new Date()
    let that = this

    this.messageTimestampTargets.forEach(function (messageDate) {
      const newTimeString = that.timeDifference(currentDate, Date.parse(messageDate.dataset.messageDate))
      if (newTimeString) messageDate.innerText = newTimeString // for older timestamps do nothing
    })
  }
  
  // based on https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time, this will calculate the difference between the current time and the time of the message & return a pretty string
  timeDifference (current, previous) {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const elapsed = current - previous

    if (elapsed < msPerMinute) {
      return 'vor weniger als einer Minute'
    } else if (elapsed < msPerHour) {
      const minutes = Math.round(elapsed / msPerMinute)
      return (minutes == 1 ? 'vor einer Minute' : 'vor ' + minutes + ' Minuten')
    } else if (elapsed < msPerDay) {
      const hours = Math.round(elapsed / msPerHour)
      return (hours == 1 ? 'vor einer Stunde' : 'vor ' + hours + ' Stunden')
    }
    return null 
  }
}
