let emailModal = document.getElementById('emailModal')
const contactFromName = document.querySelector('#contact_form_name')
const contactFromEmail = document.querySelector('#contact_form_email')
const contactFromMessage = document.querySelector('#contact_form_message')
var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/


if (emailModal) {
  emailModal.addEventListener('show.bs.modal', function (event) {
    let button = event.relatedTarget
    let recipient = button.getAttribute('data-bs-recipient')
    let recipientId = button.getAttribute('data-bs-id')

    let modalTitle = emailModal.querySelector('.modal-title')
    let modalBodyInput = emailModal.querySelector('.modal-body input#contact_form_party_executive_id')

    modalTitle.textContent = 'Nachricht an ' + recipient
    modalBodyInput.value = recipientId

    validate()
    
    contactFromMessage.addEventListener('keyup', function(){
      validate()
    })

    contactFromName.addEventListener('keyup', function(){
      validate()
    })

    contactFromEmail.addEventListener('keyup', function(){
      validate()
    })

    document.querySelector('#contact_form_personal_data_submit_accepted').addEventListener('change', function(){
      validate()
    })
  })
}


function validate() {
  let cta = document.getElementById('party-contact-cta')
  let x_checked = document.getElementById('contact_form_personal_data_submit_accepted').checked || false
  if(contactFromName.value.length > 0 && regexEmail.test(contactFromEmail.value) == true && contactFromMessage.value.length > 0 && x_checked) { 
    cta.disabled = false 
  } else { 
    cta.disabled = true 
  }
}
