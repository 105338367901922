import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['longText', 'shortText']

  showMore (event) {
    this.longTextTarget.classList.remove('d-none')
    this.shortTextTarget.classList.add('d-none')

    event.preventDefault()
  }

  showLess (event) {
    this.longTextTarget.classList.add('d-none')
    this.shortTextTarget.classList.remove('d-none')

    event.preventDefault()
  }
}
