let myElement = document.querySelector('[id=countdown-surveys]')
if (myElement !== null) {
  let split_day = myElement.attributes[1].value.split('-')
  let split_time = myElement.attributes[2].nodeName.split(':')
  simplyCountdown(myElement, {
    year: split_day[0], // required
    month: split_day[1], // required
    day: split_day[2], // required
    hours: split_time[0], // Default is 0 [0-23] integer
    minutes: split_time[1], // Default is 0 [0-59] integer
    seconds: split_time[2], // Default is 0 [0-59] integer
    words: { //words displayed into the countdown
      days: { singular: 'Tag', plural: 'Tage' },
      hours: { singular: 'Stunde', plural: 'Stunden' },
      minutes: { singular: 'Minute', plural: 'Minuten' },
      seconds: { singular: 'Sekunde', plural: 'Sekunden' }
    },
    plural: true, //use plurals
    inline: true
  })
}
