$(document).ready ->
  triggerSearch = (value) ->
    value ||= $('input#search')[0].value
    window.location.replace("/aktuelles?utf8=✓&term=#{value}")

  $('input#search').autocomplete
    source: "/aktuelles/autocomplete"
    appendTo: '#search-results'
    select: (event, ui) ->
      triggerSearch(ui.item.label)
      return false

  $('#search-icon').click ->
    triggerSearch()
