function hiddenTextOnBottom(element){
  $(element).bootstrapTable({
    formatRecordsPerPage: function (pageNumber) {
      return `${pageNumber}`
    },
    formatShowingRows: function () {
      return ' '
    },
  })
}

hiddenTextOnBottom('#backend-table')
hiddenTextOnBottom('#backend-table-agenda-right-tab')
hiddenTextOnBottom('#backend-table-my-discussions')
hiddenTextOnBottom('#backend-table-archived-dissussions')
hiddenTextOnBottom('#backend-table-scrubbed-users')
