var Trix = require('trix') // eslint-disable-line

/* insert the button visual in the default toolbar */
addEventListener('trix-initialize', function(event) {

  var bold_button = event.target.toolbarElement.
    querySelector('button[data-trix-attribute="bold"]')

  var italic_button = event.target.toolbarElement.
    querySelector('button[data-trix-attribute="italic"]')

  var heading_button = event.target.toolbarElement.
    querySelector('button[data-trix-attribute="heading1"]')

  bold_button.setAttribute('title', 'Fett')

  bold_button.setAttribute('data-trix-key', 'F')

  italic_button.setAttribute('title', 'Kursiv')

  italic_button.setAttribute('data-trix-key', 'K')

  heading_button.setAttribute('title', 'Überschrift')

  heading_button.setAttribute('data-trix-key', 'Üb')

  event.target.toolbarElement.
    querySelector('.trix-button-group--file-tools').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group--history-tools').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-strike').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-quote').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-code').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-bullet-list').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-decrease-nesting-level').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-increase-nesting-level').remove()

  event.target.toolbarElement.
    querySelector('.trix-button-group .trix-button--icon-number-list').remove()
})

window.addEventListener('trix-file-accept', function(event) {
  event.preventDefault()
})
