import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {
    this.formTarget.scrollIntoView()


    const inputExtraAmount = document.querySelector('.subscription_extra_amount_box')
    if (inputExtraAmount) inputExtraAmount.style.display = 'none'

    const advertisedFalse = document.getElementById('geworben_false')

    if (advertisedFalse && advertisedFalse.checked)  document.querySelector('.subscription-invite').style.display = 'none'
  }
  
  toggleInputExtraAmount() {
    const inputExtraAmount = document.querySelector('.subscription_extra_amount_box')

    if (inputExtraAmount.style.display == 'none') {
      inputExtraAmount.style.display = 'block'
      document.getElementById('subscription_extra_amount').focus()
    } else {
      inputExtraAmount.style.display = 'none'
    }
  }

  toggleSubscriptionInvite() {
    document.querySelector('.subscription-invite').style.display = 'block'
  }
}
