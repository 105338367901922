import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addItem', 'template']

  addAssociation(event) {
    event.preventDefault()
    let templateFragement = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addItemTarget.insertAdjacentHTML('beforebegin', templateFragement)
    const serviceField = "input[data-action='keypress->nested-form#addAssociationByKeypress']"
    const numbersOfServices = document.querySelectorAll(serviceField).length
    document.querySelectorAll(serviceField)[numbersOfServices - 1].focus()
  }

  removeAssociation(event) {
    event.preventDefault()
    const item = event.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  addAssociationByKeypress(event) {
    if (event.keyCode == 13) this.addAssociation(event)
  }
}
