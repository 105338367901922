import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'submitMessage']

  messageTargetConnected(element) {
    if (element.dataset.authorId == this.currentUserId) {
      element.classList.add('current-users-message')
    } else {
      element.classList.add('users-message')
    }
  }

  get currentUserId() {
    return document.getElementById('backend-user-id').dataset.currentBackendUserId
  }

  resetForm() {
    this.submitMessageTarget.reset()
  }
}
