/**
 * Note: some piece of this code was copied from https://github.com/itmammoth/rails_sortable/blob/7944dc3b281b74f822654dff02f7311fc4d13235/vendor/assets/javascripts/plugin.js
 * Some part of the code has been modified to fit into the application using Rails/UJS
 * DO NOT EDIT UNLESS YOU ARE SURE OF WHAT YOU ARE DOING!!!.
 */

module.exports = function($, Rails) {

  $.fn.railsSortable = function(options) {
    options = options || {}
    var settings = $.extend({}, options)
  
    settings.update = function(event, ui) {
      if (typeof options.update === 'function') {
        options.update(event, ui)
      }
  
      Rails.ajax({
        type: 'POST',
        url: '/sortable/reorder',
        dataType: 'json',
        contentType: 'application/json',
        data: new URLSearchParams({
          'rails_sortable': $(this).sortable('toArray')
        }).toString()
      })
    }
  
    this.sortable(settings)
  }
}
