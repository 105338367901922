import { delegate } from 'tippy.js'

document.addEventListener('DOMContentLoaded', () => {
  delegate('.admin-body', {
    target: '[data-tippy-content]',
    theme: 'light',
    popperOptions: {
      strategy: 'fixed'
    }
  })
})
